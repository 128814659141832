export const castInfo = [
    {
        name: "南　絢音",
        castId: "2",
        themeColor: "rgba(255, 172, 149)",
        links: {
            twitter: "https://twitter.com/ayane_m_yg",
            youtube: "https://www.youtube.com/channel/UCoWS4d-52qpApaGbBKHvQ5A",
            tickets: "https://www.yumenographia.com/tickets/?casts=2"
        }
    },
    {
        name: "聖 グレイル",
        castId: "3",
        themeColor: "rgba(162, 255, 250)",
        links: {
            twitter: "https://twitter.com/grail_yg",
            youtube: "https://www.youtube.com/channel/UCHnfJmPoLv1wAr6vYhAXShA",
            tickets: "https://www.yumenographia.com/tickets/?casts=3",
            summerNotification: "https://twitter.com/Grail_YG/status/1427183359642402816"
        }
    },
    {
        name: "六道　カロ",
        castId: "4",
        themeColor: "rgba(255, 195, 255)",
        links: {
            twitter: "https://twitter.com/karo_r_yg",
            youtube: "https://www.youtube.com/channel/UCvA6AYRJPUSrGZp31kayhEQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=4"
        }
    },
    {
        name: "宝生　てあ",
        castId: "5",
        themeColor: "rgba(138, 0, 34)",
        links: {
            twitter: "https://twitter.com/tea_h_yg",
            youtube: "https://www.youtube.com/channel/UCIS6mGDlL1l9Dz-_6WaGbHA",
            tickets: "https://www.yumenographia.com/tickets/?casts=5",
            summerNotification: "https://twitter.com/Tea_H_YG/status/1425763379885445121"
        }
    },
    {
        name: "鳩岡　小恋",
        castId: "9",
        themeColor: "rgba(255, 232, 80)",
        links: {
            twitter: "https://twitter.com/coco_h_yg",
            youtube: "https://www.youtube.com/channel/UCTdqUU98050254KJrglvRpw",
            tickets: "https://www.yumenographia.com/tickets/?casts=9",
            summerNotification: "https://twitter.com/Coco_H_YG/status/1423496925886783491"
        }
    },
    {
        name: "ころぼし　まう",
        castId: "10",
        themeColor: "rgba(34, 216, 206)",
        links: {
            twitter: "https://twitter.com/mau_k_yg",
            youtube: "https://www.youtube.com/channel/UC9RO_y3q3S6CezWPUZwchjg",
            tickets: "https://www.yumenographia.com/tickets/?casts=10",
            summerNotification: "https://twitter.com/Mau_K_YG/status/1428983700356689921",
        }
    },
    {
        name: "観影　みゆ",
        castId: "11",
        themeColor: "rgba(153, 167, 180)",
        links: {
            twitter: "https://twitter.com/miyu_m_yg",
            youtube: "https://www.youtube.com/channel/UCyZZHHbrQm6TTq-OCw1lm1Q",
            tickets: "https://www.yumenographia.com/tickets/?casts=11",
            summerNotification: "https://twitter.com/Miyu_M_YG/status/1423861637254041602"
        }
    },
    {
        name: "筆染　四季",
        castId: "12",
        themeColor: "rgba(112, 88, 163)",
        links: {
            twitter: "https://twitter.com/shiki_f_yg",
            youtube: "https://www.youtube.com/channel/UCDvrSLOnQdzaFS2IZENY5OA",
            tickets: "https://www.yumenographia.com/tickets/?casts=12",
            summerNotification: "https://twitter.com/Shiki_F_YG/status/1426126592544186371"
        }
    },
    // {
    //     name: "雎　雪待",
    //     castId: "13",
    //     themeColor: "rgba(0, 0, 51)",
    //     links: {
    //         twitter: "https://twitter.com/yukimachi_m_yg",
    //         youtube: "https://www.youtube.com/channel/UCT_njgtN2a8Ju13_NrmSs5Q",
    //         tickets: "https://www.yumenographia.com/tickets/?casts=13"
    //     }
    // },
    {
        name: "苺ノ森　こひな",
        castId: "14",
        themeColor: "rgba(255, 105, 151)",
        links: {
            twitter: "https://twitter.com/kohina_i_yg",
            youtube: "https://www.youtube.com/channel/UCYBcsFLhWktypwOK60rrHDg",
            tickets: "https://www.yumenographia.com/tickets/?casts=14"
        }
    },
    {
        name: "小花　てくと",
        castId: "15",
        themeColor: "rgba(245, 216, 146)",
        links: {
            twitter: "https://twitter.com/techt_k_yg",
            youtube: "https://www.youtube.com/channel/UC-6Qa_TGe4t_7O0VAA_dt9g",
            tickets: "https://www.yumenographia.com/tickets/?casts=15"
        }
    },
    {
        name: "りいか",
        castId: "16",
        themeColor: "rgba(255, 10, 133)",
        links: {
            twitter: "https://twitter.com/reeka_yg",
            youtube: "https://www.youtube.com/channel/UCj2OT0HavdPpe_QMeYSzlDQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=16",
            summerNotification: "https://twitter.com/Reeka_YG/status/1424332128657973248"
        }
    },
    {
        name: "四ノ宮 透華",
        castId: "17",
        themeColor: "rgba(177, 132, 153)",
        links: {
            twitter: "https://twitter.com/touca_s_yg",
            youtube: "https://www.youtube.com/channel/UC2qHAqJrfFKgqBpnFV1NnGg",
            tickets: "https://www.yumenographia.com/tickets/?casts=17",
            summerNotification: "https://twitter.com/touca_S_YG/status/1427919723413401605"
        }
    },
    // {
    //     name: "ロッド・クロウリー",
    //     castId: "18",
    //     themeColor: "rgba(112, 189, 255)",
    //     links: {
    //         twitter: "https://twitter.com/rod_c_yg",
    //         youtube: "https://www.youtube.com/channel/UCd5GupJDWS5OsjztuD0IGhw",
    //         tickets: "https://www.yumenographia.com/tickets/?casts=18"
    //     }
    // },
    {
        name: "紅夜　ぺい",
        castId: "19",
        themeColor: "rgba(241, 131, 0)",
        links: {
            twitter: "https://twitter.com/pei_k_yg",
            youtube: "https://www.youtube.com/channel/UCTBm2KwIhQR6BwiQZtRxl7g",
            tickets: "https://www.yumenographia.com/tickets/?casts=19"
        }
    },
    {
        name: "蝿取　かづら",
        castId: "20",
        themeColor: "rgba(195, 216, 37)",
        links: {
            twitter: "https://twitter.com/kazura_h_yg",
            youtube: "https://www.youtube.com/channel/UC8ytn7bH048TWtE1MeSkuvQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=20"
        }
    },
    {
        name: "ミーティア",
        castId: "21",
        themeColor: "rgba(154, 13, 124)",
        links: {
            twitter: "https://twitter.com/meteor_yg",
            youtube: "https://www.youtube.com/channel/UCY_bDOGjQA3fXmgIqruQAAg",
            tickets: "https://www.yumenographia.com/tickets/?casts=21",
            summerNotification: "https://twitter.com/Meteor_YG/status/1424340157625368576"
        }
    },
    {
        name: "朝霧　いづる",
        castId: "22",
        themeColor: "rgba(104, 169, 207)",
        links: {
            twitter: "https://twitter.com/izuru_a_yg",
            youtube: "https://www.youtube.com/channel/UCkMafPPXcU3D15G4aqxZx_Q",
            tickets: "https://www.yumenographia.com/tickets/?casts=22",
            summerNotification: "https://twitter.com/izuru_A_YG/status/1423636838766374912"
        }
    },
    {
        name: "暮間　珀暮",
        castId: "23",
        themeColor: "rgba(255, 205, 51)",
        links: {
            twitter: "https://twitter.com/hakubo_yg",
            youtube: "https://www.youtube.com/channel/UCRNrakpqXd8RTN9WWjeENIg",
            tickets: "https://www.yumenographia.com/tickets/?casts=23"
        }
    },
    {
        name: "蒼海　カヤナ",
        castId: "24",
        themeColor: "rgba(148, 215, 199)",
        links: {
            twitter: "https://twitter.com/kayana_a_yg",
            youtube: "https://www.youtube.com/channel/UC51CAf3nsDsjOGwHRcncYHA",
            tickets: "https://www.yumenographia.com/tickets/?casts=24",
            summerNotification: "https://twitter.com/Kayana_A_YG/status/1426502108488953859"
        }
    },
    {
        name: "雛芽　るち",
        castId: "25",
        themeColor: "rgba(255, 230, 244, 0.9)",
        links: {
            twitter: "https://twitter.com/ruchi_h_yg",
            youtube: "https://www.youtube.com/channel/UCekwKFXWWGehHV3kvnR23OQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=25"
        }
    },
    {
        name: "福寿　礼",
        castId: "26",
        themeColor: "rgba(237, 66, 255)",
        links: {
            twitter: "https://twitter.com/rei_f_yg",
            youtube: "https://www.youtube.com/channel/UCR-fHN56IDgABP7wlnr3uPw",
            tickets: "https://www.yumenographia.com/tickets/?casts=26",
            summerNotification: "https://twitter.com/Rei_F_YG/status/1423504541404655621"
        }
    },
    // {
    //     name: "海老名　ラムネ",
    //     castId: "27",
    //     themeColor: "rgba(197, 237, 253)",
    //     links: {
    //         twitter: "https://twitter.com/ramune_e_yg",
    //         youtube: "https://www.youtube.com/channel/UCas0jhhLl9l2FbJZ_BQxBkA",
    //         tickets: "https://www.yumenographia.com/tickets/?casts=27"
    //     }
    // },
    {
        name: "園田　小陽",
        castId: "28",
        themeColor: "rgba(199, 79, 48)",
        links: {
            twitter: "https://twitter.com/koharu_s_yg",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=28"
        }
    },
    {
        name: "御仲　いのり",
        castId: "29",
        themeColor: "rgba(253, 245, 230)",
        links: {
            twitter: "https://twitter.com/inori_o_yg",
            youtube: "https://www.youtube.com/channel/UCZp7mfxUzWKj3rTaZqLiLLg",
            tickets: "https://www.yumenographia.com/tickets/?casts=29"
        }
    },
    {
        name: "天矢　のの",
        castId: "30",
        themeColor: "rgba(0, 191, 255)",
        links: {
            twitter: "https://twitter.com/nono_a_yg",
            youtube: "https://www.youtube.com/channel/UCtndV98K-NfSa9KpaV85FRw",
            tickets: "https://www.yumenographia.com/tickets/?casts=30",
            summerNotification: "https://twitter.com/Nono_A_YG/status/1424684603562893325?"
        }
    },
    {
        name: "信濃　奈乃",
        castId: "31",
        themeColor: "rgba(137, 201, 151)",
        links: {
            twitter: "https://twitter.com/nano_s_yg",
            youtube: "https://www.youtube.com/channel/UClrMHJ0Zl8xpC8pqTdPo9ww",
            tickets: "https://www.yumenographia.com/tickets/?casts=31",
            summerNotification: "https://twitter.com/Nano_S_YG/status/1425422071811633152",
        }
    },
    {
        name: "朱華　うる",
        castId: "32",
        themeColor: "rgba(255, 201, 147)",
        links: {
            twitter: "https://twitter.com/uru_h_yg",
            youtube: "https://www.youtube.com/channel/UCpPGELHdYfYeJQq80S3mcgA",
            tickets: "https://www.yumenographia.com/tickets/?casts=32",
            summerNotification: "https://twitter.com/Uru_H_YG/status/1423559940296245250"
        }
    },
    {
        name: "杠　芽里",
        castId: "33",
        themeColor: "rgba(179, 34, 34)",
        links: {
            twitter: "https://twitter.com/meri_y_yg",
            youtube: "https://www.youtube.com/channel/UCHsHLKm2s0uLVp4-evVBerw",
            tickets: "https://www.yumenographia.com/tickets/?casts=33",
            summerNotification: "https://twitter.com/Meri_Y_YG/status/1424309224306135042"
        }
    },
    {
        name: "明燈　みちか",
        castId: "34",
        themeColor: "rgba(192, 168, 132)",
        links: {
            twitter: "https://twitter.com/Michika_A_YG",
            youtube: "https://www.youtube.com/channel/UCH8Ef-KxZcDY-17Db83LGJw",
            tickets: "https://www.yumenographia.com/tickets/?casts=34",
            summerNotification: "https://twitter.com/Michika_A_YG/status/1423621360702476295"
        }
    },
    {
        name: "池鯉鮒 ももり",
        castId: "35",
        themeColor: "rgba(250, 192, 217)",
        links: {
            twitter: "https://twitter.com/Momori_C_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=35",
            summerNotification: "https://twitter.com/Momori_C_YG/status/1425704711697485827"
        }
    },
    // {
    //     name: "檸檬 かふる",
    //     castId: "36",
    //     themeColor: "rgba(255, 250, 150)",
    //     links: {
    //         twitter: "https://twitter.com/Kafuru_L_YG",
    //         youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
    //         tickets: "https://www.yumenographia.com/tickets/?casts=36"
    //     }
    // },
    {
        name: "帆孝 清澄",
        castId: "37",
        themeColor: "rgba(42, 61, 32)",
        links: {
            twitter: "https://twitter.com/Kiyosumi_H_YG",
            youtube: "https://www.youtube.com/channel/UCDiZtUxpF9f3TwSXKk2FhaQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=37",
            summerNotification: "https://twitter.com/Kiyosumi_H_YG/status/1423566785157111808"
        }
    },
    {
        name: "百瀬 あさひ",
        castId: "38",
        themeColor: "rgba(107, 142, 35)",
        links: {
            twitter: "https://twitter.com/Asahi_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=38"
        }
    },
    {
        name: "リナ・リア",
        castId: "39",
        themeColor: "rgba(150, 0, 67)",
        links: {
            twitter: "https://twitter.com/Leah_R_YG",
            youtube: "https://www.youtube.com/channel/UCaHXzDnB3oA2orV3F6w4gMQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=39",
            summerNotification: "https://twitter.com/Leah_R_YG/status/1427215979826745345"
        }
    },
    {
        name: "乙花 クラリス",
        castId: "40",
        themeColor: "rgba(227, 247, 205)",
        links: {
            twitter: "https://twitter.com/Kurarisu_O_YG",
            youtube: "https://www.youtube.com/channel/UChE8F6hjE5G6Frcps-0qiKw",
            tickets: "https://www.yumenographia.com/tickets/?casts=40",
            summerNotification: "https://twitter.com/Kurarisu_O_YG/status/1425554959706189824"
        }
    },
    {
        name: "望月 らて",
        castId: "41",
        themeColor: "rgba(219, 255, 163)",
        links: {
            twitter: "https://twitter.com/Latte_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=41",
            summerNotification: "https://twitter.com/Latte_M_YG/status/1423950839333355524"
        }
    },
    // {
    //     name: "シェナ・レクター",
    //     castId: "42",
    //     themeColor: "rgba(201, 23, 30)",
    //     links: {
    //         twitter: "https://twitter.com/Shiena_l_YG",
    //         youtube: "https://www.youtube.com/channel/UCbVaiamahjY7Hegjxd3MN5Q",
    //         tickets: "https://www.yumenographia.com/tickets/?casts=42",
    //         summerNotification: "https://twitter.com/Shiena_l_YG/status/1425682548491919362?s=20"
    //     }
    // },
    {
        name: "町無 こがれ",
        castId: "44",
        themeColor: "rgba(255, 177, 205)",
        links: {
            twitter: "https://twitter.com/Kogare_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=44",
            summerNotification: "https://twitter.com/Kogare_M_YG/status/1423540228699017217"
        }
    },
    {
        name: "萌葱 もえ",
        castId: "45",
        themeColor: "rgba(0, 109, 77)",
        links: {
            twitter: "https://twitter.com/Moe_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=45",
            summerNotification: "https://twitter.com/Moe_M_YG/status/1425435719820972043"
        }
    },
    {
        name: "ニコラ・カルチベート",
        castId: "46",
        themeColor: "rgba(46, 46, 46)",
        links: {
            twitter: "https://twitter.com/Nicola_C_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=46",
            summerNotification: "https://twitter.com/Nicola_C_YG/status/1426482232693202947"
        }
    },
    {
        name: "ロゼ・ブランシェ",
        castId: "47",
        themeColor: "rgba(252, 232, 230)",
        links: {
            twitter: "https://twitter.com/Rose_B_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=47",
            summerNotification: "https://twitter.com/Rose_B_YG/status/1427233935013027849"
        }
    },
    {
        name: "甘露 かえで",
        castId: "48",
        themeColor: "rgba(255, 223, 184)",
        links: {
            twitter: "https://twitter.com/Kaede_A_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=48",
            summerNotification: "https://twitter.com/Kaede_A_YG/status/1426382725175410692"
        }
    },
    {
        name: "茶澪 ミュスカ",
        castId: "49",
        themeColor: "rgba(196, 247, 116)",
        links: {
            twitter: "https://twitter.com/Muscat_S_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=49",
            summerNotification: "https://twitter.com/Muscat_S_YG/status/1426793481389756421"
        }
    },
    {
        name: "ひととせ ククイ",
        castId: "50",
        themeColor: "rgba(255, 215, 171)",
        links: {
            twitter: "https://twitter.com/Kukui_H_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=50",
            summerNotification: "https://twitter.com/Kukui_H_YG/status/1425427491632545795"
        }
    },
    {
        name: "鶴巳 すみれ",
        castId: "51",
        themeColor: "rgba(73, 227, 190)",
        links: {
            twitter: "https://twitter.com/Sumire_T_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=51",
            summerNotification: "https://twitter.com/Sumire_T_YG/status/1425475037553848322"
        }
    },
    {
        name: "看木 メイ",
        castId: "52",
        themeColor: "rgba(220, 188, 227)",
        links: {
            twitter: "https://twitter.com/Mei_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=52",
            summerNotification: "https://twitter.com/Mei_M_YG/status/1424014480162185216"
        }
    },
    {
        name: "神音 アオハ",
        castId: "53",
        themeColor: "rgba(10, 63, 148)",
        links: {
            twitter: "https://twitter.com/Aoha_S_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=53",
            summerNotification: "https://twitter.com/Aoha_S_YG/status/1423876906106032129"
        }
    },
    {
        name: "アイリス・サマーフィールド",
        castId: "54",
        themeColor: "rgba(143, 93, 150)",
        links: {
            twitter: "https://twitter.com/Iris_S_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=54",
            summerNotification: "https://twitter.com/Iris_S_YG/status/1424015492700786689"
        }
    },
    {
        name: "柚良 ひより",
        castId: "55",
        themeColor: "rgba(246, 189, 255)",
        links: {
            twitter: "https://twitter.com/Hiyori_Y_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=55",
            summerNotification: "https://twitter.com/Hiyori_Y_YG/status/1425418069208223751"
        }
    },
    {
        name: "鴉羽 律",
        castId: "43",
        themeColor: "rgba(141, 160, 182)",
        links: {
            twitter: "https://twitter.com/Ritsu_K_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=43",
            summerNotification: "https://twitter.com/Ritsu_K_YG/status/1435912895615815686?s=20"
        }
    },
    {
        name: "雪深々 恋白",
        castId: "56",
        themeColor: "rgba(246, 191, 188)",
        links: {
            twitter: "https://twitter.com/Koshiro_Y_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=56",
            
        }
    },
    {
        name: "冬月 みや",
        castId: "59",
        themeColor: "rgba(163, 6, 37)",
        links: {
            twitter: "https://twitter.com/Miya_F_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=59",
        }
    },
    {
        name: "工藤 依愛",
        castId: "60",
        themeColor: "rgba(250, 188, 112)",
        links: {
            twitter: "https://twitter.com/Ia_K_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=60",
            
        }
    },
    {
        name: "彩菫 栞",
        castId: "57",
        themeColor: "rgba(227, 211, 242)",
        links: {
            twitter: "https://twitter.com/Shiori_A_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=57",
            
        }
    },
    {
        name: "似我蜂 すがる",
        castId: "61",
        themeColor: "rgba(144, 139, 191)",
        links: {
            twitter: "https://twitter.com/Sugaru_J_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=61",
            
        }
    }
    
]

export const castInfoIncludedGraduated = [
    {
        name: "片倉 一実",
        castId: "1",
        themeColor: "rgba(76, 111, 217)",
        links: {
            twitter: "https://twitter.com/Hitomi_K_YG",
            youtube: "https://www.youtube.com/channel/UComcV5JIFj9hy24DDOywcpQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=1"
        }
    },
    {
        name: "南　絢音",
        castId: "2",
        themeColor: "rgba(255, 172, 149)",
        links: {
            twitter: "https://twitter.com/ayane_m_yg",
            youtube: "https://www.youtube.com/channel/UCoWS4d-52qpApaGbBKHvQ5A",
            tickets: "https://www.yumenographia.com/tickets/?casts=2"
        }
    },
    {
        name: "聖 グレイル",
        castId: "3",
        themeColor: "rgba(162, 255, 250)",
        links: {
            twitter: "https://twitter.com/grail_yg",
            youtube: "https://www.youtube.com/channel/UCHnfJmPoLv1wAr6vYhAXShA",
            tickets: "https://www.yumenographia.com/tickets/?casts=3"
        }
    },
    {
        name: "六道　カロ",
        castId: "4",
        themeColor: "rgba(255, 195, 255)",
        links: {
            twitter: "https://twitter.com/karo_r_yg",
            youtube: "https://www.youtube.com/channel/UCvA6AYRJPUSrGZp31kayhEQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=5"
        }
    },
    {
        name: "宝生　てあ",
        castId: "5",
        themeColor: "rgba(138, 0, 34)",
        links: {
            twitter: "https://twitter.com/tea_h_yg",
            youtube: "https://www.youtube.com/channel/UCIS6mGDlL1l9Dz-_6WaGbHA",
            tickets: "https://www.yumenographia.com/tickets/?casts=4"
        }
    },
    {
        name: "鳩岡　小恋",
        castId: "9",
        themeColor: "rgba(255, 232, 80)",
        links: {
            twitter: "https://twitter.com/coco_h_yg",
            youtube: "https://www.youtube.com/channel/UCTdqUU98050254KJrglvRpw",
            tickets: "https://www.yumenographia.com/tickets/?casts=9"
        }
    },
    {
        name: "ころぼし　まう",
        castId: "10",
        themeColor: "rgba(34, 216, 206)",
        links: {
            twitter: "https://twitter.com/mau_k_yg",
            youtube: "https://www.youtube.com/channel/UC9RO_y3q3S6CezWPUZwchjg",
            tickets: "https://www.yumenographia.com/tickets/?casts=10"
        }
    },
    {
        name: "観影　みゆ",
        castId: "11",
        themeColor: "rgba(153, 167, 180)",
        links: {
            twitter: "https://twitter.com/miyu_m_yg",
            youtube: "https://www.youtube.com/channel/UCyZZHHbrQm6TTq-OCw1lm1Q",
            tickets: "https://www.yumenographia.com/tickets/?casts=11"
        }
    },
    {
        name: "筆染　四季",
        castId: "12",
        themeColor: "rgba(112, 88, 163)",
        links: {
            twitter: "https://twitter.com/shiki_f_yg",
            youtube: "https://www.youtube.com/channel/UCDvrSLOnQdzaFS2IZENY5OA",
            tickets: "https://www.yumenographia.com/tickets/?casts=12"
        }
    },
    {
        name: "雎　雪待",
        castId: "13",
        themeColor: "rgba(0, 0, 51)",
        links: {
            twitter: "https://twitter.com/yukimachi_m_yg",
            youtube: "https://www.youtube.com/channel/UCT_njgtN2a8Ju13_NrmSs5Q",
            tickets: "https://www.yumenographia.com/tickets/?casts=13"
        }
    },
    {
        name: "苺ノ森　こひな",
        castId: "14",
        themeColor: "rgba(255, 105, 151)",
        links: {
            twitter: "https://twitter.com/kohina_i_yg",
            youtube: "https://www.youtube.com/channel/UCYBcsFLhWktypwOK60rrHDg",
            tickets: "https://www.yumenographia.com/tickets/?casts=14"
        }
    },
    {
        name: "小花　てくと",
        castId: "15",
        themeColor: "rgba(245, 216, 146)",
        links: {
            twitter: "https://twitter.com/techt_k_yg",
            youtube: "https://www.youtube.com/channel/UC-6Qa_TGe4t_7O0VAA_dt9g",
            tickets: "https://www.yumenographia.com/tickets/?casts=15"
        }
    },
    {
        name: "りいか",
        castId: "16",
        themeColor: "rgba(255, 10, 133)",
        links: {
            twitter: "https://twitter.com/reeka_yg",
            youtube: "https://www.youtube.com/channel/UCj2OT0HavdPpe_QMeYSzlDQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=16"
        }
    },
    {
        name: "四ノ宮 透華",
        castId: "17",
        themeColor: "rgba(177, 132, 153)",
        links: {
            twitter: "https://twitter.com/touca_s_yg",
            youtube: "https://www.youtube.com/channel/UC2qHAqJrfFKgqBpnFV1NnGg",
            tickets: "https://www.yumenographia.com/tickets/?casts=17"
        }
    },
    {
        name: "ロッド・クロウリー",
        castId: "18",
        themeColor: "rgba(112, 189, 255)",
        links: {
            twitter: "https://twitter.com/rod_c_yg",
            youtube: "https://www.youtube.com/channel/UCd5GupJDWS5OsjztuD0IGhw",
            tickets: "https://www.yumenographia.com/tickets/?casts=18"
        }
    },
    {
        name: "紅夜　ぺい",
        castId: "19",
        themeColor: "rgba(241, 131, 0)",
        links: {
            twitter: "https://twitter.com/pei_k_yg",
            youtube: "https://www.youtube.com/channel/UCTBm2KwIhQR6BwiQZtRxl7g",
            tickets: "https://www.yumenographia.com/tickets/?casts=19"
        }
    },
    {
        name: "蝿取　かづら",
        castId: "20",
        themeColor: "rgba(195, 216, 37)",
        links: {
            twitter: "https://twitter.com/kazura_h_yg",
            youtube: "https://www.youtube.com/channel/UC8ytn7bH048TWtE1MeSkuvQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=20"
        }
    },
    {
        name: "ミーティア",
        castId: "21",
        themeColor: "rgba(154, 13, 124)",
        links: {
            twitter: "https://twitter.com/meteor_yg",
            youtube: "https://www.youtube.com/channel/UCY_bDOGjQA3fXmgIqruQAAg",
            tickets: "https://www.yumenographia.com/tickets/?casts=21"
        }
    },
    {
        name: "朝霧　いづる",
        castId: "22",
        themeColor: "rgba(104, 169, 207)",
        links: {
            twitter: "https://twitter.com/izuru_a_yg",
            youtube: "https://www.youtube.com/channel/UCkMafPPXcU3D15G4aqxZx_Q",
            tickets: "https://www.yumenographia.com/tickets/?casts=22"
        }
    },
    {
        name: "暮間　珀暮",
        castId: "23",
        themeColor: "rgba(255, 205, 51)",
        links: {
            twitter: "https://twitter.com/hakubo_yg",
            youtube: "https://www.youtube.com/channel/UCRNrakpqXd8RTN9WWjeENIg",
            tickets: "https://www.yumenographia.com/tickets/?casts=23"
        }
    },
    {
        name: "蒼海　カヤナ",
        castId: "24",
        themeColor: "rgba(148, 215, 199)",
        links: {
            twitter: "https://twitter.com/kayana_a_yg",
            youtube: "https://www.youtube.com/channel/UC51CAf3nsDsjOGwHRcncYHA",
            tickets: "https://www.yumenographia.com/tickets/?casts=24"
        }
    },
    {
        name: "雛芽　るち",
        castId: "25",
        themeColor: "rgba(255, 230, 244, 0.9)",
        links: {
            twitter: "https://twitter.com/ruchi_h_yg",
            youtube: "https://www.youtube.com/channel/UCekwKFXWWGehHV3kvnR23OQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=25"
        }
    },
    {
        name: "福寿　礼",
        castId: "26",
        themeColor: "rgba(237, 66, 255)",
        links: {
            twitter: "https://twitter.com/rei_f_yg",
            youtube: "https://www.youtube.com/channel/UCR-fHN56IDgABP7wlnr3uPw",
            tickets: "https://www.yumenographia.com/tickets/?casts=26"
        }
    },
    {
        name: "海老名　ラムネ",
        castId: "27",
        themeColor: "rgba(197, 237, 253)",
        links: {
            twitter: "https://twitter.com/ramune_e_yg",
            youtube: "https://www.youtube.com/channel/UCas0jhhLl9l2FbJZ_BQxBkA",
            tickets: "https://www.yumenographia.com/tickets/?casts=27"
        }
    },
    {
        name: "園田　小陽",
        castId: "28",
        themeColor: "rgba(199, 79, 48)",
        links: {
            twitter: "https://twitter.com/koharu_s_yg",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=28"
        }
    },
    {
        name: "御仲　いのり",
        castId: "29",
        themeColor: "rgba(253, 245, 230)",
        links: {
            twitter: "https://twitter.com/inori_o_yg",
            youtube: "https://www.youtube.com/channel/UCZp7mfxUzWKj3rTaZqLiLLg",
            tickets: "https://www.yumenographia.com/tickets/?casts=29"
        }
    },
    {
        name: "天矢　のの",
        castId: "30",
        themeColor: "rgba(0, 191, 255)",
        links: {
            twitter: "https://twitter.com/nono_a_yg",
            youtube: "https://www.youtube.com/channel/UCtndV98K-NfSa9KpaV85FRw",
            tickets: "https://www.yumenographia.com/tickets/?casts=30"
        }
    },
    {
        name: "信濃　奈乃",
        castId: "31",
        themeColor: "rgba(137, 201, 151)",
        links: {
            twitter: "https://twitter.com/nano_s_yg",
            youtube: "https://www.youtube.com/channel/UClrMHJ0Zl8xpC8pqTdPo9ww",
            tickets: "https://www.yumenographia.com/tickets/?casts=31"
        }
    },
    {
        name: "朱華　うる",
        castId: "32",
        themeColor: "rgba(255, 201, 147)",
        links: {
            twitter: "https://twitter.com/uru_h_yg",
            youtube: "https://www.youtube.com/channel/UCpPGELHdYfYeJQq80S3mcgA",
            tickets: "https://www.yumenographia.com/tickets/?casts=32"
        }
    },
    {
        name: "杠　芽里",
        castId: "33",
        themeColor: "rgba(179, 34, 34)",
        links: {
            twitter: "https://twitter.com/meri_y_yg",
            youtube: "https://www.youtube.com/channel/UCHsHLKm2s0uLVp4-evVBerw",
            tickets: "https://www.yumenographia.com/tickets/?casts=33",
            summerNotification: "https://twitter.com/Meri_Y_YG/status/1424309224306135042"
        }
    },
    {
        name: "明燈　みちか",
        castId: "34",
        themeColor: "rgba(192, 168, 132)",
        links: {
            twitter: "https://twitter.com/Michika_A_YG",
            youtube: "https://www.youtube.com/channel/UCH8Ef-KxZcDY-17Db83LGJw",
            tickets: "https://www.yumenographia.com/tickets/?casts=34",
            summerNotification: "https://twitter.com/Michika_A_YG/status/1423621360702476295"
        }
    },
    {
        name: "池鯉鮒 ももり",
        castId: "35",
        themeColor: "rgba(250, 192, 217)",
        links: {
            twitter: "https://twitter.com/Momori_C_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=35"
        }
    },
    {
        name: "檸檬 かふる",
        castId: "36",
        themeColor: "rgba(255, 250, 150)",
        links: {
            twitter: "https://twitter.com/Kafuru_L_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=36"
        }
    },
    {
        name: "帆孝 清澄",
        castId: "37",
        themeColor: "rgba(42, 61, 32)",
        links: {
            twitter: "https://twitter.com/Kiyosumi_H_YG",
            youtube: "https://www.youtube.com/channel/UCDiZtUxpF9f3TwSXKk2FhaQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=37"
        }
    },
    {
        name: "百瀬 あさひ",
        castId: "38",
        themeColor: "rgba(107, 142, 35)",
        links: {
            twitter: "https://twitter.com/Asahi_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=38"
        }
    },
    {
        name: "リナ・リア",
        castId: "39",
        themeColor: "rgba(150, 0, 67)",
        links: {
            twitter: "https://twitter.com/Leah_R_YG",
            youtube: "https://www.youtube.com/channel/UCaHXzDnB3oA2orV3F6w4gMQ",
            tickets: "https://www.yumenographia.com/tickets/?casts=39"
        }
    },
    {
        name: "乙花 クラリス",
        castId: "40",
        themeColor: "rgba(227, 247, 205)",
        links: {
            twitter: "https://twitter.com/Kurarisu_O_YG",
            youtube: "https://www.youtube.com/channel/UChE8F6hjE5G6Frcps-0qiKw",
            tickets: "https://www.yumenographia.com/tickets/?casts=40"
        }
    },
    {
        name: "望月 らて",
        castId: "41",
        themeColor: "rgba(219, 255, 163)",
        links: {
            twitter: "https://twitter.com/Latte_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=41"
        }
    },
    {
        name: "シェナ・レクター",
        castId: "42",
        themeColor: "rgba(201, 23, 30)",
        links: {
            twitter: "https://twitter.com/Shiena_l_YG",
            youtube: "https://www.youtube.com/channel/UCbVaiamahjY7Hegjxd3MN5Q",
            tickets: "https://www.yumenographia.com/tickets/?casts=42"
        }
    },
    {
        name: "町無 こがれ",
        castId: "44",
        themeColor: "rgba(255, 177, 205)",
        links: {
            twitter: "https://twitter.com/Kogare_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=44"
        }
    },
    {
        name: "萌葱 もえ",
        castId: "45",
        themeColor: "rgba(0, 109, 77)",
        links: {
            twitter: "https://twitter.com/Moe_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=44",
            summerNotification: "https://twitter.com/Moe_M_YG/status/1425435719820972043"
        }
    },
    {
        name: "ニコラ・カルチベート",
        castId: "46",
        themeColor: "rgba(46, 46, 46)",
        links: {
            twitter: "https://twitter.com/Nicola_C_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=46",
            summerNotification: "https://twitter.com/Nicola_C_YG/status/1426482232693202947"
        }
    },
    {
        name: "ロゼ・ブランシェ",
        castId: "47",
        themeColor: "rgba(252, 232, 230)",
        links: {
            twitter: "https://twitter.com/Rose_B_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=47"
        }
    },
    {
        name: "甘露 かえで",
        castId: "48",
        themeColor: "rgba(255, 223, 184)",
        links: {
            twitter: "https://twitter.com/Kaede_A_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=48",
            summerNotification: "https://twitter.com/Kaede_A_YG/status/1426382725175410692"
        }
    },
    {
        name: "茶澪 ミュスカ",
        castId: "49",
        themeColor: "rgba(196, 247, 116)",
        links: {
            twitter: "https://twitter.com/Muscat_S_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=49"
        }
    },
    {
        name: "ひととせ ククイ",
        castId: "50",
        themeColor: "rgba(255, 215, 171)",
        links: {
            twitter: "https://twitter.com/Kukui_H_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=50"
        }
    },
    {
        name: "鶴巳 すみれ",
        castId: "51",
        themeColor: "rgba(73, 227, 190)",
        links: {
            twitter: "https://twitter.com/Sumire_T_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=51"
        }
    },
    {
        name: "看木 メイ",
        castId: "52",
        themeColor: "rgba(220, 188, 227)",
        links: {
            twitter: "https://twitter.com/Mei_M_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=52"
        }
    },
    {
        name: "神音 アオハ",
        castId: "53",
        themeColor: "rgba(10, 63, 148)",
        links: {
            twitter: "https://twitter.com/Aoha_S_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=53"
        }
    },
    {
        name: "アイリス・サマーフィールド",
        castId: "54",
        themeColor: "rgba(143, 93, 150)",
        links: {
            twitter: "https://twitter.com/Iris_S_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=54"
        }
    },
    {
        name: "柚良 ひより",
        castId: "55",
        themeColor: "rgba(246, 189, 255)",
        links: {
            twitter: "https://twitter.com/Hiyori_Y_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=55"
        }
    },
    {
        name: "鴉羽 律",
        castId: "43",
        themeColor: "rgba(141, 160, 182)",
        links: {
            twitter: "https://twitter.com/Ritsu_K_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=43",
        }
    },
    {
        name: "雪深々 恋白",
        castId: "56",
        themeColor: "rgba(246, 191, 188)",
        links: {
            twitter: "https://twitter.com/Koshiro_Y_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=56",
            
        }
    },
    {
        name: "冬月 みや",
        castId: "59",
        themeColor: "rgba(163, 6, 37)",
        links: {
            twitter: "https://twitter.com/Miya_F_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=59",
        }
    },
    {
        name: "工藤 依愛",
        castId: "60",
        themeColor: "rgba(250, 188, 112)",
        links: {
            twitter: "https://twitter.com/Ia_K_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=60",
            
        }
    },
    {
        name: "彩菫 栞",
        castId: "57",
        themeColor: "rgba(227, 211, 242)",
        links: {
            twitter: "https://twitter.com/Shiori_A_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=57",
            
        }
    },
    {
        name: "似我蜂 すがる",
        castId: "61",
        themeColor: "rgba(144, 139, 191)",
        links: {
            twitter: "https://twitter.com/Sugaru_J_YG",
            youtube: "https://www.youtube.com/channel/UCvgwkCllAvUNjpe4F6_-GCw",
            tickets: "https://www.yumenographia.com/tickets/?casts=61",
            
        }
    }
]